<template>
  <div>
    <div v-if="fieldSupplySummaryData.length > 0">
      <v-toolbar>
        <v-text-field
          v-model="includedSearch"
          label="Search By Attribute"
          prepend-inner-icon="mdi-magnify"
          variant="outlined"
          hide-details
          single-line
        ></v-text-field>
        <v-spacer></v-spacer>
        <v-text-field
          label="Search by Field ID"
          v-model="fieldIDString"
          prepend-inner-icon="mdi-magnify"
          density="compact"
          variant="solo"
          hide-details
          clearable
          single-line
          @click:clear="fieldIDSearch('')"
          @click:prepend-inner="fieldIDSearch(fieldIDString)"
          @keydown.enter="fieldIDSearch(fieldIDString)"
        ></v-text-field>
        <v-btn
          class="ma-3 pa-3"
          color="primary"
          v-if="selectedFSSForAllocationFiltered.length > 0"
          @click="handleFSSDetailsModal(selectedFSSForAllocationFiltered)"
        >
          Allocate for {{ selectedFSSForAllocationFiltered.length }} Field Crops
          ( {{ selectedAcreage }} acres)</v-btn
        >
      </v-toolbar>
      <v-data-table
        v-model="selectedFSSForAllocation"
        :headers="enrollmentHeaders"
        :items="fieldSupplySummaryDataFiltered"
        :search="includedSearch"
        :items-per-page="100"
        item-key="id"
        show-select
      >
        <template v-slot:header="{ header }">
          <VDataTablesMultiSelectHeader
            :items="fieldSupplySummaryData"
            :headers="enrollmentHeaders"
            :filters="filters"
            showIcon="true"
            @filtered-data="filteredData"
          />
        </template>
        <template slot="item.fieldcrop.field_name" slot-scope="props">
          <span
            class="hover"
            @click="handeShowFieldDetailsCompletionModal(props.item)"
            style="max-width: 100px"
          >
            {{ props.item.fieldcrop.field_name }}
            <v-icon color="blue"> mdi-list-status </v-icon>
          </span>
        </template>

        <template slot="item.ea_orders_name" slot-scope="props">
          <v-btn
            v-if="
              props.item.num_matches > 0 && props.item.status == 'available'
            "
            color="dark green lighten-1"
            small
            @click="handleFSSDetailsModal(props.item)"
            >Select</v-btn
          >
          <span v-if="props.item.ea_orders"
            >{{ props.item.ea_orders.name }}
            <i
              v-if="props.item.status == 'locked'"
              class="pt-2 fa fa-times-circle float-right"
              @click="handleOpenFSSRemoveModal(props.item)"
            ></i>
          </span>
        </template>
        <!--<template slot="item.ea_orders" slot-scope="props">
          <span v-if="props.item.ea_orders"
            >{{ props.item.ea_orders.name }}
            <i
              v-if="props.item.status == 'locked'"
              class="pt-2 fa fa-times-circle float-right"
              @click="handleOpenFSSRemoveModal(props.item)"
            ></i>
          </span>
        </template>-->
        <template slot="item.practices" slot-scope="props">
          <span v-if="props.item.practices" v-html="props.item.practices">
          </span>
        </template>
        <template v-slot:item.data-table-select="{ item, isSelected, select }">
          <v-simple-checkbox
            v-if="item.status == 'available'"
            :readonly="item.status != 'available'"
            :disabled="item.status != 'available'"
            :value="isSelected"
            @input="select($event)"
          ></v-simple-checkbox>
        </template>
      </v-data-table>
    </div>
    <div v-else>
      <div v-if="showLoading" class="mt-3 d-flex justify-content-center">
        <div class="text-bold mb-0">Fetching Data</div>
        <div class="spinner-border ml-4" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>
      <div v-else>
        <h4>
          No Data Currently Available For Year In Focus: {{ yearInFocus }}
        </h4>
      </div>
    </div>

    <ReviewFSSDetails
      v-if="showFSSDetailsModal"
      :items="items"
      :fssPrograms="fssPrograms"
      :fssID="selectedFssID"
      title="Field Supply Details"
      @close-modal="showFSSDetailsModal = false"
      @save-from-modal="handleConfirmFSSAddModal"
    />

    <ConfirmFSSRemovalModal
      v-if="selectedOrderID && showRemoveFSSModal"
      :selectedOrderName="selectedOrderName"
      :selectedOrderID="selectedOrderID"
      :fssID="selectedFssID"
      @close-modal="handleCloseFSSRemoveModal"
      @save-from-modal="handleConfirmFSSRemoveModal"
    />

    <FieldDetailsCompletion
      v-if="showFieldDetailsCompletion"
      :fieldID="selectedFieldID"
      :year="yearInFocus"
      @close-modal="handleCloseFieldDetailsCompletion"
    />
  </div>
</template>

<script>
import { Filter } from "@/store/modules"
import CarbonReadyAPI from "@/api/CarbonReadyAPI"
import _ from "lodash"
import { mapState, mapGetters } from "vuex"
import ReviewFSSDetails from "@/components/insets/modals/ReviewFSSDetails"
import ConfirmFSSRemovalModal from "@/components/insets/modals/ConfirmFSSRemovalModal"
import FieldDetailsCompletion from "@/components/insets/modals/FieldDetailsCompletionModal"
import VDataTablesMultiSelectHeader from "@/components/misc/VDataTablesMultiSelectHeader"

export default {
  name: "FieldCropProgramManagement",
  components: {
    ReviewFSSDetails,
    ConfirmFSSRemovalModal,
    FieldDetailsCompletion,
    VDataTablesMultiSelectHeader,
  },
  props: ["activeFields"],
  data() {
    return {
      selectedFSSForAllocation: [],
      showLoading: false,
      filterFieldSupplySummary: [],
      fieldSupplySummaryDataFiltered: [],
      filters: {
        crop_name: [],
        status: [],
        ea_orders_name: [],
        //matched_program: [],
      },
      fssPrograms: [],
      items: [],
      includedSearch: "",
      selectedFssID: null,
      showFSSDetailsModal: false,
      showRemoveFSSModal: false,
      showFieldDetailsCompletion: false,
      selectedOrderID: null,
      selectedOrerName: null,
      selectedFieldID: null,
      fieldIDString: "",
      enrollmentHeaders: [
        { text: "FSS ID", value: "id", width: "5%" },
        { text: "Field ID", value: "fieldcrop.field_id", width: "5%" },
        { text: "Field", value: "fieldcrop.field_name", width: "17%" },
        { text: "Client", value: "client", width: "8%" },
        { text: "AC", value: "fieldcrop.acreage", width: "5%" }, //acres not currently available in serializer
        { text: "Crop", value: "crop_name", width: "15%" },
        { text: "Practices", value: "practices", width: "10%" },
        //{ text: "% Completion", value: "completion" },
        //{ text: "Evidence", value: "evidence" },
        { text: "Status", value: "status", width: "12%" },

        //{ text: "Practices", value: "practices" },
        { text: "EA Program", value: "ea_orders_name", width: "20%" },
        //{ text: "Matched Program", value: "matched_program", align: " d-none" },

        //{ text: "Matched Program", value: "ea_orders" },
      ],
    }
  },

  methods: {
    filteredData(e) {
      this.fieldSupplySummaryDataFiltered = e
    },
    async fetchFieldSupplySummary() {
      const payload = {
        year: this.yearInFocus,
        exclude_open: true,
      }
      this.showLoading = true
      await CarbonReadyAPI.getFieldSupplySummary(payload).then(resp => {
        let fieldSupplySummary = resp.data.results

        this.filterFieldSupplySummary = fieldSupplySummary.filter(
          e => e.status != "open"
        )
        this.showLoading = false
      })
    },

    fieldIDSearch(fieldIDString) {
      if (fieldIDString == "" || fieldIDString == null) {
        this.fieldSupplySummaryDataFiltered = this.filterFieldSupplySummary
      } else {
        let fieldIDs = []
        let fieldIDsSplit = fieldIDString.split(",")

        fieldIDsSplit.forEach(e => {
          e.split(" ").forEach(f => {
            if (f != " ") {
              fieldIDs.push(f)
            }
          })
        })

        this.fieldSupplySummaryDataFiltered =
          this.filterFieldSupplySummary.filter(e => {
            return fieldIDs.includes(String(e.fieldcrop.field_id))
          })
      }
    },

    handeShowFieldDetailsCompletionModal(fss) {
      this.selectedFieldID = fss.fieldcrop.field_id
      this.showFieldDetailsCompletion = true
    },
    handleCloseFieldDetailsCompletion() {
      this.selectedFieldID = null
      this.showFieldDetailsCompletion = false
    },

    handleFSSDetailsModal(fss) {
      if (Array.isArray(fss)) {
        this.fssPrograms = []

        let fss_ids = fss.map(e => e.id)

        CarbonReadyAPI.postFSSRecommendations({
          fss_ids: fss.map(e => e.id),
        }).then(response => {
          this.fssPrograms = response.data[1]

          this.selectedFssID = fss_ids

          this.showFSSDetailsModal = true
        })
      } else {
        this.fssPrograms = []

        CarbonReadyAPI.getFSSRecommendations({
          fss_id: fss.id,
        }).then(response => {
          this.fssPrograms = response.data[1]

          this.selectedFssID = fss.id

          this.showFSSDetailsModal = true
        })
      }
    },

    handleOpenFSSRemoveModal(e) {
      this.selectedFssID = e.id
      this.selectedOrderName = e.ea_orders.name
      this.selectedOrderID = e.ea_orders.id

      this.showRemoveFSSModal = true
    },
    handleConfirmFSSAddModal() {
      this.selectedFssID = null
      this.showFSSDetailsModal = false
      this.fetchFieldSupplySummary()
      this.$emit("refreshAllocation")
    },
    handleCloseFSSRemoveModal() {
      this.selectedFssID = null

      this.selectedOrderID = null
      this.selectedOrderName = null
      this.showRemoveFSSModal = false
    },
    handleConfirmFSSRemoveModal() {
      this.selectedFssID = null

      this.selectedOrderID = null
      this.selectedOrderName = null
      this.showRemoveFSSModal = false

      this.fetchFieldSupplySummary()
      this.$emit("refreshAllocation")
    },
  },

  computed: {
    ...mapState({
      yearInFocus: state => state.Organization.year,
    }),
    ...mapGetters({
      selectedFields: Filter.Getters.getSelectedFields,
    }),

    selectedAcreage() {
      let acreages = this.selectedFSSForAllocationFiltered.map(e => {
        return e.fieldcrop.acreage
      })

      let acreage_sum = acreages.reduce((partialSum, a) => partialSum + a, 0)
      return acreage_sum
    },

    selectedFSSForAllocationFiltered() {
      return this.selectedFSSForAllocation.filter(e => e.status == "available")
    },
    fieldSupplySummaryData() {
      let activeFieldIds = this.activeFields.map(field => field.id)

      this.filterFieldSupplySummary.forEach(e => {
        if (Array.isArray(e.practices)) {
          let practice_list = "<ul>"
          e.practices.forEach(p => {
            practice_list += "<li>" + p.split(":")[1] + "</li>"
          })
          practice_list += "</ul>"
          e.practices = practice_list
        }
        e.fieldcrop.acreage = Math.round(e.fieldcrop.acreage)
        e.crop_name = e.fieldcrop.crop_name
        e.ea_orders_name = null
        if (e.ea_orders != null) {
          e.ea_orders_name = e.ea_orders.name
        }
      })
      if (activeFieldIds.length > 0) {
        return this.filterFieldSupplySummary.filter(e => {
          return _.includes(activeFieldIds, e.fieldcrop.field_id)
        })
      } else {
        return this.filterFieldSupplySummary
      }
    },
  },

  watch: {
    yearInFocus() {
      this.fetchFieldSupplySummary()
    },
  },

  async mounted() {
    await this.fetchFieldSupplySummary()
    this.fieldSupplySummaryDataFiltered = this.fieldSupplySummaryData
  },
}
</script>

<style scoped>
.fa-times-circle {
  cursor: pointer;
  color: #e60000;
}
.hover {
  cursor: pointer;
  color: #0095ff;
  font-weight: 500;
}
</style>
