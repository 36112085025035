<template>
  <ArvaModal
    name="editClientProfileModal"
    :title="title"
    @close-modal="handleCloseModal"
  >
    <v-card-text>
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-text-field
          v-model="selectedClientProfile.poc_email"
          label="POC Email"
          required
          :rules="[emailRule]"
        ></v-text-field>
        <v-text-field
          v-model="selectedClientProfile.phone_number"
          label="Phone Number"
          required
        ></v-text-field>
        <v-text-field
          v-model="selectedClientProfile.street_address"
          label="Street Address"
          required
        ></v-text-field>
        <v-text-field
          v-model="selectedClientProfile.city"
          label="City"
          required
        ></v-text-field>
        <v-select
          v-model="selectedClientProfile.state"
          :items="states"
          label="State"
          required
        ></v-select>
        <v-text-field
          v-model="selectedClientProfile.zip_code"
          label="Zip Code"
          required
          :rules="[zipCodeRule]"
        ></v-text-field>
      </v-form>
    </v-card-text>

    <ArvaModalSaveFooter
      confirmText="Save"
      cancelText="Cancel"
      @on-cancel="handleCloseModal"
      @on-confirm="saveClientProfile"
    />
  </ArvaModal>
</template>

<script>
import ArvaModal from "./ArvaModal"
import ArvaModalSaveFooter from "@/components/modals/ArvaModalSaveFooter"

export default {
  name: "AlertErrorModal",
  props: ["selectedClientProfile"],
  components: { ArvaModal, ArvaModalSaveFooter },
  data() {
    return {
      valid: true,
      zipCodeRule: value =>
        /^[0-9]+$/.test(value) || "ZIP code must be numeric",
      emailRule: value =>
        /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value) || "Invalid email address",
      states: [
        "AL",
        "AK",
        "AZ",
        "AR",
        "CA",
        "CO",
        "CT",
        "DE",
        "FL",
        "GA",
        "HI",
        "ID",
        "IL",
        "IN",
        "IA",
        "KS",
        "KY",
        "LA",
        "ME",
        "MD",
        "MA",
        "MI",
        "MN",
        "MS",
        "MO",
        "MT",
        "NE",
        "NV",
        "NH",
        "NJ",
        "NM",
        "NY",
        "NC",
        "ND",
        "OH",
        "OK",
        "OR",
        "PA",
        "RI",
        "SC",
        "SD",
        "TN",
        "TX",
        "UT",
        "VT",
        "VA",
        "WA",
        "WV",
        "WI",
        "WY",
      ],
    }
  },
  computed: {
    title() {
      return this.selectedClientProfile
        ? `Edit Client Profile: ${this.selectedClientProfile.client.name}`
        : "Edit Client Profile"
    },
  },
  methods: {
    handleCloseModal() {
      this.$emit("close-modal")
    },
    saveClientProfile() {
      this.$emit("save-client-profile", this.selectedClientProfile)
    },
  },
}
</script>

<style scoped></style>
