import _ from "lodash"

export {
  UPLOAD_DATA_ACTION,
  ASSIGN_USERS_ACTION,
  EXPORT_TABLE_ACTION,
  UNTRACKED_EVIDENCING,
  CLIENT_SPECIFICITY,
  FIELD_SPECIFICITY,
  FARM_SPECIFICITY,
  EVIDENCING_REMOVAL,
  EVIDENCING_CREATION,
  EVIDENCING_DATE_RULES,
  LOADING_SUCCESS,
  LOADING_FAILURE,
  LOADING_PROCESSING,
} from "./evidencing"

export const ARVA_WEB_APP = "Arva Web App"
export const EVIDENCING_MOBILE = "Evidencing Mobile App"

const crops = {
  Alfalfa: 36,
  Barley: 22,
  BlackBeans: 16,
  Camelina: 37,
  Canola: 12,
  Corn: 4,
  Cotton: 8,
  Durum: 43,
  Fallow: 35,
  FieldPeas: 39,
  GarbanzoBeans: 38,
  Generic: 27,
  HighOleicSoybeans: 40,
  Millet: 15,
  Milo: 34,
  Oats: 14,
  Pasture: 28,
  Peanuts: 26,
  Range: 29,
  RapeSeed: 31,
  Rice: 6,
  Rye: 32,
  Sorghum: 11,
  Soybeans: 5,
  SugarBeets: 45,
  Sugarcane: 46,
  SunflowerOil: 13,
  SweetPotato: 48,
  Tobacco: 47,
  Triticale: 33,
  Wheat: 7,
  WinterWheat: 30,
}

export const CROPS = crops

export const CROP_YIELD_LIMITS = {
  4: 400,
  5: 100,
  7: 200,
  6: 300,
  8: 5,
  11: 200,
}

export const CROP_DISPLAY_NAME = {
  [CROPS.Corn]: "Corn",
  [CROPS.Soybeans]: "Soybeans",
  [CROPS.Wheat]: "Wheat",
  [CROPS.Rice]: "Rice",
  [CROPS.Cotton]: "Cotton",
  [CROPS.Sorghum]: "Sorghum",
  [CROPS.Canola]: "Canola",
  [CROPS.Alfalfa]: "Alfalfa",
  [CROPS.SunflowerOil]: "Sunflower Oil",
  [CROPS.Oats]: "Oats",
  [CROPS.Millet]: "Millet",
  [CROPS.BlackBeans]: "Black Beans",
  [CROPS.Barley]: "Barley",
  [CROPS.Peanuts]: "Peanuts",
  [CROPS.Generic]: "Generic",
  [CROPS.Pasture]: "Pasture",
  [CROPS.Range]: "Range",
  [CROPS.WinterWheat]: "Winter Wheat",
  [CROPS.RapeSeed]: "Rapeseed",
  [CROPS.Rye]: "Rye",
  [CROPS.Triticale]: "Triticale",
  [CROPS.Milo]: "Milo",
  [CROPS.Fallow]: "Fallow",
  [CROPS.Camelina]: "Camelina",
  [CROPS.GarbanzoBeans]: "Garbanzo Beans",
  [CROPS.FieldPeas]: "Field Peas",
  [CROPS.HighOleicSoybeans]: "High Oleic Soybeans",
  [CROPS.Durum]: "Durum",
  [CROPS.SugarBeets]: "Sugar Beets",
  [CROPS.Sugarcane]: "Sugarcane",
  [CROPS.SweetPotato]: "Sweet Potato",
  [CROPS.Tobacco]: "Tobacco",
}

export const CROP_KEY = {
  [CROPS.Corn]: "corn",
  [CROPS.Soybeans]: "soybeans",
  [CROPS.Wheat]: "wheat",
  [CROPS.Rice]: "rice",
  [CROPS.Cotton]: "cotton",
  [CROPS.Sorghum]: "sorghum",
  [CROPS.Canola]: "canola",
  [CROPS.Alfalfa]: "alfalfa",
  [CROPS.SunflowerOil]: "sunfloweroil",
  [CROPS.Oats]: "oats",
  [CROPS.Millet]: "millet",
  [CROPS.BlackBeans]: "blackbeans",
  [CROPS.Barley]: "barley",
  [CROPS.Peanuts]: "peanuts",
  [CROPS.Generic]: "generic",
  [CROPS.Pasture]: "pasture",
  [CROPS.Range]: "range",
  [CROPS.WinterWheat]: "winter wheat",
  [CROPS.RapeSeed]: "rapeseed",
  [CROPS.Rye]: "rye",
  [CROPS.Triticale]: "triticale",
  [CROPS.Milo]: "milo",
  [CROPS.Fallow]: "fallow",
  [CROPS.Camelina]: "camelina",
  [CROPS.GarbanzoBeans]: "garbanzobeans",
  [CROPS.FieldPeas]: "fieldpeas",
  [CROPS.HighOleicSoybeans]: "high oleic soybeans",
  [CROPS.Durum]: "durum",
  [CROPS.SugarBeets]: "sugar beets",
  [CROPS.Sugarcane]: "sugar cane",
  [CROPS.SweetPotato]: "sweet potato",
  [CROPS.Tobacco]: "tobacco",
}

export const CROP_ID_KEY = _.mapValues(_.invert(CROP_KEY), parseInt)

export const CROP_KEY_DISPLAY_NAME = {
  [CROP_KEY[CROPS.Corn]]: "Corn",
  [CROP_KEY[CROPS.Soybeans]]: "Soybeans",
  [CROP_KEY[CROPS.Wheat]]: "Wheat",
  [CROP_KEY[CROPS.Rice]]: "Rice",
  [CROP_KEY[CROPS.Cotton]]: "Cotton",
  [CROP_KEY[CROPS.Sorghum]]: "Sorghum",
  [CROP_KEY[CROPS.Canola]]: "Canola",
  [CROP_KEY[CROPS.Alfalfa]]: "Alfalfa",
  [CROP_KEY[CROPS.SunflowerOil]]: "Sunflower Oil",
  [CROP_KEY[CROPS.Oats]]: "Oats",
  [CROP_KEY[CROPS.Millet]]: "Millet",
  [CROP_KEY[CROPS.BlackBeans]]: "Black Beans",
  [CROP_KEY[CROPS.Barley]]: "Barley",
  [CROP_KEY[CROPS.Peanuts]]: "Peanuts",
  [CROP_KEY[CROPS.Generic]]: "Generic",
  [CROP_KEY[CROPS.Pasture]]: "Pasture",
  [CROP_KEY[CROPS.Range]]: "Range",
  [CROP_KEY[CROPS.WinterWheat]]: "Winter Wheat",
  [CROP_KEY[CROPS.RapeSeed]]: "Rapeseed",
  [CROP_KEY[CROPS.Rye]]: "Rye",
  [CROP_KEY[CROPS.Triticale]]: "Triticale",
  [CROP_KEY[CROPS.Milo]]: "Milo",
  [CROP_KEY[CROPS.Fallow]]: "Fallow",
  [CROP_KEY[CROPS.Camelina]]: "Camelina",
  [CROP_KEY[CROPS.GarbanzoBeans]]: "Garbanzo Beans",
  [CROP_KEY[CROPS.FieldPeas]]: "Field Peas",
  [CROP_KEY[CROPS.HighOleicSoybeans]]: "High Oleic Soybeans",
  [CROP_KEY[CROPS.Durum]]: "Durum",
  [CROP_KEY[CROPS.SugarBeets]]: "Sugar Beets",
  [CROP_KEY[CROPS.Sugarcane]]: "Sugarcane",
  [CROP_KEY[CROPS.SweetPotato]]: "Sweet Potato",
  [CROP_KEY[CROPS.Tobacco]]: "Tobacco",
}

export const CROP_DROPDOWN_OPTIONS = Object.values(CROPS).map(cropId => ({
  value: cropId,
  display_name: CROP_DISPLAY_NAME[cropId],
}))

export const CROP_NAMES_SELECT = Object.values(CROP_KEY).map(cropId => cropId)

export const CROP_ID_TO_ICON_URL = {
  [CROPS.Corn]: "/assets/images/crop_icons/corn.png",
  [CROPS.Soybeans]: "/assets/images/crop_icons/soybeans.png",
  [CROPS.Rice]: "/assets/images/crop_icons/rice.png",
  [CROPS.Wheat]: "/assets/images/crop_icons/flour.svg",
  [CROPS.Cotton]: "/assets/images/crop_icons/cotton.svg",
  ["Other"]: "/assets/images/crop_icons/flour.svg",
}

export const CROP_KEY_TO_ICON_URL = {
  [CROP_KEY[CROPS.Corn]]: CROP_ID_TO_ICON_URL[CROPS.Corn],
  [CROP_KEY[CROPS.Soybeans]]: CROP_ID_TO_ICON_URL[CROPS.Soybeans],
  [CROP_KEY[CROPS.Wheat]]: CROP_ID_TO_ICON_URL[CROPS.Wheat],
  [CROP_KEY[CROPS.Rice]]: CROP_ID_TO_ICON_URL[CROPS.Rice],
  [CROP_KEY[CROPS.Cotton]]: CROP_ID_TO_ICON_URL[CROPS.Cotton],
  ["Other"]: CROP_ID_TO_ICON_URL["Other"],
}

export const CROP_SEEDS_PER_BAG = {
  [CROPS.Corn]: 80000,
  [CROPS.Soybeans]: 140000,
  [CROPS.Wheat]: 50,
  [CROPS.Cotton]: 1,
  [CROPS.Rice]: 50,
}

export const CROP_PRICES_CENTS = {
  [CROPS.Corn]: 425,
  [CROPS.Soybeans]: 1050,
  [CROPS.Wheat]: 525,
  [CROPS.Cotton]: 65,
  [CROPS.Rice]: 550,
}

export const CROP_SEEDING_RATES = {
  [CROPS.Corn]: 34500,
  [CROPS.Soybeans]: 140000,
  [CROPS.Wheat]: 110,
  [CROPS.Cotton]: 28000,
  [CROPS.Rice]: 24,
}

export const CROP_SEED_BAG_RATES = {
  [CROPS.Corn]: 80000,
  [CROPS.Soybeans]: 140000,
}

export const MAP_LAYER = {
  Microclimate: 0,
  FieldSelection: 1,
  Boundary: 2,
  AGT: 3,
  Elevation: 4,
  // Harvest: 5,
  // YieldByElevation: 6,
  // Fertilizer: 7,
  // Planting: 8,
  Planting: 5,
  Fertilizer: 6,
  Harvest: 7,
  YieldByElevation: 8,
  Profit: 9,
  AGTByElevation: 10,
  ProfitByAGT: 11,
  Crops2: 12,
  Crops: 13,
  FieldBorder: 14,
}

export const MAP_LAYER_NAME = {
  [MAP_LAYER.Boundary]: "Field Boundaries",
  [MAP_LAYER.AGT]: "AGT",
  [MAP_LAYER.Harvest]: "Harvest",
  [MAP_LAYER.Elevation]: "Elevation",
  [MAP_LAYER.YieldByElevation]: "Yield By Elevation",
  [MAP_LAYER.Fertilizer]: "Fertilizer As Applied",
  [MAP_LAYER.Planting]: "Planting",
  [MAP_LAYER.Profit]: "Profit",
  [MAP_LAYER.AGTByElevation]: "AGT By Elevation",
  [MAP_LAYER.ProfitByAGT]: "Profit by AGT",
  [MAP_LAYER.Microclimate]: "Microclimate",
}

export const DROPDOWN = {
  OrgNode: "orgNode",
  Crop: "crop",
  Client: "client",
  Farm: "farm",
  Field: "field",
}

export const US_STATES = [
  "Alabama",
  "Alaska",
  "Arizona",
  "Arkansas",
  "California",
  "Colorado",
  "Connecticut",
  "Delaware",
  "Florida",
  "Georgia",
  "Hawaii",
  "Idaho",
  "Illinois",
  "Indiana",
  "Iowa",
  "Kansas",
  "Kentucky",
  "Louisiana",
  "Maine",
  "Maryland",
  "Massachusetts",
  "Michigan",
  "Minnesota",
  "Mississippi",
  "Missouri",
  "Montana",
  "Nebraska",
  "Nevada",
  "New Hampshire",
  "New Jersey",
  "New Mexico",
  "New York",
  "North Carolina",
  "North Dakota",
  "Ohio",
  "Oklahoma",
  "Oregon",
  "Pennsylvania",
  "Rhode Island",
  "South Carolina",
  "South Dakota",
  "Tennessee",
  "Texas",
  "Utah",
  "Vermont",
  "Virginia",
  "Washington",
  "West Virginia",
  "Wisconsin",
  "Wyoming",
]

export const YES_NO_OPTIONS = [
  { value: true, display_name: "Yes" },
  { value: false, display_name: "No" },
]

export const MICROCLIMATE_COLOR_FACTOR = 200

let _BASE_URL = "http://localhost:8000/"
let _DEPLOYMENT_ENVIRONMENT = "local"

if (import.meta.env.VITE_NODE_ENV === "production") {
  _BASE_URL = "https://app.cropforce.com/"
  _DEPLOYMENT_ENVIRONMENT = "production"
} else if (import.meta.env.VITE_NODE_ENV === "staging") {
  _BASE_URL = "https://app.staging.cropforce.com/"
  _DEPLOYMENT_ENVIRONMENT = "staging"
} else if (import.meta.env.VITE_NODE_ENV === "demo") {
  _BASE_URL = "https://app.demo.cropforce.com/"
  _DEPLOYMENT_ENVIRONMENT = "demo"
}

export const BASE_URL = _BASE_URL
// please do NOT start using this for anything but indicating the environment
export const DEPLOYMENT_ENVIRONMENT = _DEPLOYMENT_ENVIRONMENT
