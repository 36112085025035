<template>
  <div class="px-3">
    <div
      v-if="loading"
      class="my-3 d-flex justify-content-center"
      justify="center"
    >
      <div v-if="this.selectedEnrollment == null">
        <span v-if="projectReports.length == 0" class="text-h5 red--text"
          >Please Be Sure to Select An Enrollment. Tracking Items Will Appear
          Here as Enrollment Stages Progress.</span
        >
      </div>
      <div v-else>
        <div class="text-bold mb-0 mt-1">Fetching Data</div>
        <div class="spinner-border ml-4" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>
    </div>
    <div v-else>
      <v-expansion-panels v-model="panel" multiple>
        <v-expansion-panel>
          <v-expansion-panel-header class="expansion-header">
            Data Completion Review
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <!--<AllocationVisualizer :key="allocationKey" />-->
            <FieldList />
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel v-model="panel">
          <v-expansion-panel-header class="expansion-header">
            Contracts
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <span v-if="projectReports.length == 0" class="text-h5 red--text"
              >The rest of this panel will become visible once grower contract
              are generated.
              <v-btn>Dummy Contract Request Button Here</v-btn></span
            >

            <v-card v-else class="mt-6 mb-6 pb-3 pt-3">
              <v-card-title>Documents</v-card-title>
              <table class="table">
                <thead>
                  <tr>
                    <th>Report ID</th>
                    <th>Created</th>
                    <th>Type</th>
                    <th>Status</th>
                    <th>Download</th>
                  </tr>

                  <tr v-for="report in projectReports" :key="report.id">
                    <td class="hover" @click="handleSamplingPlanSelect(plan)">
                      {{ report.id }}
                    </td>
                    <td class="hover" style="max-width: 110px">
                      {{ report.created_at | date }}
                    </td>
                    <td class="hover" style="max-width: 100px">
                      {{ report.type }}
                    </td>
                    <td class="hover" style="max-width: 110px">
                      {{ report.status }}
                    </td>
                    <td class="hover" style="max-width: 100px">
                      <button
                        type="button"
                        class="btn btn-primary update-btn py-2 mr-0 float-right"
                        @click="handleReportDownload(report)"
                        v-if="report.report != null"
                      >
                        Download Report
                      </button>
                    </td>
                  </tr>
                </thead>
              </table>
            </v-card>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header class="expansion-header">
            Sampling Status Summary
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-data-table
              :headers="samplingSummaryHeaders"
              :items="samplingSummaryItems"
              :items-per-page="7"
              class="elevation-1"
              v-if="samplingSummaryItems"
              dense
              hide-default-footer
            ></v-data-table>
            <div class="d-flex mt-2 mb-2 pb-3 pt-3">
              <button
                class="btn btn-primary update-btn py-2 m-auto"
                @click="requestOrderReRun('controlPlot')"
              >
                Request Cluster Analysis Rerun
              </button>
              <button
                class="btn btn-primary update-btn py-2 m-auto"
                @click="requestOrderReRun('oppSum')"
              >
                Request Opp Summary Rerun
              </button>
              <v-snackbar
                v-model="requestMade"
                elevation="24"
                color="green lighten-1"
                >{{ requestText }}</v-snackbar
              >
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header class="expansion-header">
            Verification and Monitoring
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <!--<AllocationVisualizer :key="allocationKey" />-->
            TBD
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>

      <!-- try a  v-data-table here to show point collection-->
    </div>
  </div>
</template>
<script>
import CarbonReadyAPI from "@/api/CarbonReadyAPI"
import FieldList from "@/views/FieldList"

export default {
  name: "CarbonEnrollmentControlCenter",
  components: { FieldList },
  props: ["selectedEnrollment"],

  data() {
    return {
      projectReports: [],
      samplingSummaryItems: null,
      samplingSummaryHeaders: null,
      requestMade: false,
      requestText: "",
      loading: true,
      panel: [1],
    }
  },
  computed: {},

  methods: {
    async handleReportDownload(report) {
      window.open(report.report, "_blank")
    },
    fetchEnrollmentReports(enrollmentId) {
      CarbonReadyAPI.postProjectReports({
        enrollmentId: enrollmentId,
      }).then(response => {
        this.projectReports = response.data
        this.loading = false
      })
    },
    requestOrderReRun(type) {
      CarbonReadyAPI.postOrderRerun({
        enrollmentId: this.selectedEnrollment.id,
        type: type,
      }).then(() => {
        this.requestText = "Reprocess Request Received"
        this.requestMade = true
      })
    },
    fetchEnrollmentSampleSummary(enrollmentId) {
      CarbonReadyAPI.getEnrollmentSamplingSummary({
        enrollmentId: enrollmentId,
      }).then(response => {
        this.samplingSummaryHeaders = response.data["headers"]
        this.samplingSummaryItems = response.data["data"]
      })
    },
  },
  async mounted() {
    if (this.selectedEnrollment != null) {
      await this.fetchEnrollmentReports(this.selectedEnrollment.id)
      await this.fetchEnrollmentSampleSummary(this.selectedEnrollment.id)
    }
  },
}
</script>

<style scoped>
.expansion-header {
  background-color: #d3d3d350;
}
</style>
