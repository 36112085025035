<template>
  <div>
    <Permissions package="inset-enrollment" :superUserOnly="false" />
    <div v-if="showInsetEnrollment">
      <div class="client-profile-title">
        Client Profile
        <i
          class="fa fa-info-circle opp-info"
          data-toggle="tooltip"
          data-placement="top"
          title="This is the client profile page. Here you can view, edit client information and send Letter Of Intent (LOI) to clients."
        />
      </div>
      <v-row class="pt-3">
        <v-col cols="12">
          <v-card>
            <v-card-text>
              <div v-if="showLoading" class="text-center py-3">
                <v-progress-circular
                  :size="70"
                  :width="8"
                  color="green"
                  indeterminate
                ></v-progress-circular>
                <div>
                  <h4>
                    Please be patient when loading many clients - this may take
                    a couple minutes.
                  </h4>
                </div>
              </div>

              <div v-else-if="clientProfile.length > 0">
                <v-simple-table>
                  <thead>
                    <tr>
                      <th>Client ID</th>
                      <th>Client Name</th>
                      <th>Point Of Contact (POC)</th>
                      <th>Phone Number</th>
                      <th>Signed LOI</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="record in clientProfile" :key="record.id">
                      <td>{{ record.client.id }}</td>
                      <td>{{ record.client.name }}</td>
                      <td>{{ record.poc_email }}</td>
                      <td>{{ formatPhoneNumber(record.phone_number) }}</td>
                      <td>
                        <span
                          v-if="record.has_signed"
                          class="icon-check"
                        ></span>
                        <span v-else class="icon-x"></span>
                      </td>
                      <td>
                        <v-btn
                          class="mr-2"
                          color="grey"
                          dark
                          small
                          @click="openEditClientProfile(record)"
                          >Edit Profile</v-btn
                        >
                        <v-btn
                          v-if="record.loi_pdf"
                          color="success"
                          dark
                          small
                          @click="downloadLOI(record.loi_pdf)"
                        >
                          Download LOI
                        </v-btn>
                        <v-btn
                          v-else
                          color="warning"
                          dark
                          small
                          @click="openLOI(record)"
                        >
                          Generate LOI
                        </v-btn>
                      </td>
                    </tr>
                  </tbody>
                </v-simple-table>
              </div>

              <div v-else class="text-center py-3">
                <h1>No Client Found For {{ org.name }}</h1>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <EditClientProfileModal
        v-if="openEditClientProfileModal"
        :selectedClientProfile="selectedClient"
        @close-modal="openEditClientProfileModal = false"
        @save-client-profile="saveClientProfile"
      />

      <LetterOfIntentModal
        v-if="openLOIModal"
        :selectedClient="selectedClient"
        :org="org"
        :year="year"
        @close-modal="openLOIModal = false"
        @send-loi="sendLOIToClient"
      />
    </div>
  </div>
</template>

<script>
import _ from "lodash"
import { mapState } from "vuex"
import CarbonFlowSteppers from "@/views/CarbonFlowSteppers"
import Permissions from "@/components/permissions/Permissions"
import EditClientProfileModal from "../components/modals/EditClientProfileModal.vue"
import LetterOfIntentModal from "../components/modals/LetterOfIntentModal.vue"
import FieldsAPI from "@/api/FieldsAPI"

export default {
  name: "EnrollFieldsView",
  components: {
    CarbonFlowSteppers,
    Permissions,
    EditClientProfileModal,
    LetterOfIntentModal,
  },

  data() {
    return {
      showLoading: false,
      openLOIModal: false,
      clientProfile: [],
      selectedClient: null,
      openEditClientProfileModal: false,
    }
  },

  computed: {
    ...mapState({
      showSustainability: state =>
        state.Organization.organization.showSustainability,
      showInsetEnrollment: state =>
        state.Organization.organization.showInsetEnrollment,
      user: state => state.User.user,
      org: state => state.Organization.organization,
    }),
  },

  methods: {
    formatPhoneNumber(phoneNumber) {
      if (!phoneNumber) return ""
      const cleaned = phoneNumber.replace(/\D/g, "")
      const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)
      if (match) {
        return `(${match[1]})-${match[2]}-${match[3]}`
      }
      return phoneNumber
    },
    async fetchClientProfile() {
      this.showLoading = true
      await FieldsAPI.getClientProfile().then(({ data }) => {
        this.clientProfile = data
        this.showLoading = false
      })
    },
    openEditClientProfile(record) {
      this.selectedClient = record
      this.openEditClientProfileModal = true
    },
    async saveClientProfile(payload) {
      this.openEditClientProfileModal = false
      await FieldsAPI.saveClientProfile(payload).then(() => {
        this.fetchClientProfile()
      })
    },
    openLOI(record) {
      this.selectedClient = record
      this.openLOIModal = true
    },
    async sendLOIToClient(payload) {
      this.openLOIModal = false
      this.showLoading = true
      await FieldsAPI.sendLOI(payload).then(() => {
        this.fetchClientProfile()
        this.showLoading = false
      })
    },
    downloadLOI(url) {
      const link = document.createElement("a")
      link.href = url
      link.setAttribute("download", "Letter_Of_Intent.pdf")
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    },
  },

  mounted() {
    this.fetchClientProfile()
  },
}
</script>

<style scoped>
.icon-check {
  color: green;
  font-size: 1.5em;
}

.icon-check::before {
  content: "\2714";
}

.icon-x {
  color: red;
  font-size: 1.5em;
}

.icon-x::before {
  content: "\2716";
}
.client-profile-title {
  font-size: 22px;
  color: black;
  font-weight: 500;
}
</style>
