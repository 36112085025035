<template>
  <ArvaModal
    name="letterOfIntentModal"
    title="Letter Of Intent (LOI) Form"
    :wide="true"
    :width="1000"
    @close-modal="handleCloseModal"
    style="z-index: 10000"
  >
    <div>
      <div class="row">
        <div class="col-12">
          <div class="agreement">
            <div class="row">
              <div class="col-2">
                <div class="logo" />
              </div>
              <div class="col">
                <h1>
                  Application to Participate in Arva's Environmental Asset
                  Program
                </h1>
              </div>
            </div>

            <div class="row mb-3">
              <span>
                This LOI agreement pertains to Participant (Grower) request to
                participate in one or more Environmental Asset Programs. Grower
                agrees to deliver to Arva Intelligence Inc (Arva). farm data
                (both current and historical), operational management data and
                other various farm practice data for the determination for
                possible future environmental asset credits (Payments) back to
                the participant. The Participant understands that the intent of
                Arva and the Channel Partner (identified above under this lOI
                agreement) will work together to evaluate each farm and field
                for best execution to present for engagement in the
                Environmental Asset Program(s).<br />
              </span>
            </div>

            <div class="row">
              <h3>Participant</h3>
              <v-text-field
                label="Name"
                :rules="[rules.required]"
                v-model="participantName"
                style="width: 100%"
              ></v-text-field>
              <v-row>
                <v-col cols="12" md="6">
                  <v-text-field
                    label="Street Address"
                    v-model="participantAddress.street"
                    style="width: 100%"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="2">
                  <v-text-field
                    label="City"
                    v-model="participantAddress.city"
                    style="width: 100%"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="2">
                  <v-text-field
                    label="State"
                    v-model="participantAddress.state"
                    style="width: 100%"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="2">
                  <v-text-field
                    label="Zip Code"
                    v-model="participantAddress.zip"
                    style="width: 100%"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-text-field
                label="Email"
                :rules="[rules.required]"
                v-model="participantEmail"
                style="width: 100%"
              ></v-text-field>
              <v-text-field
                label="Telephone"
                v-model="participantTelephone"
                style="width: 100%"
              ></v-text-field>
            </div>

            <div class="row">
              <h3>Channel Partner</h3>
              <v-text-field
                label="Name"
                v-model="cpName"
                :rules="[rules.required]"
                style="width: 100%"
              ></v-text-field>
              <v-row>
                <v-col cols="12" md="6">
                  <v-text-field
                    label="Street Address"
                    v-model="cpAddress.street"
                    style="width: 100%"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="2">
                  <v-text-field
                    label="City"
                    v-model="cpAddress.city"
                    style="width: 100%"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="2">
                  <v-text-field
                    label="State"
                    v-model="cpAddress.state"
                    style="width: 100%"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="2">
                  <v-text-field
                    label="Zip Code"
                    v-model="cpAddress.zip"
                    style="width: 100%"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-text-field
                label="Email"
                :rules="[rules.required]"
                v-model="cpEmail"
                style="width: 100%"
              ></v-text-field>
              <v-text-field
                label="Telephone"
                v-model="cpTelephone"
                style="width: 100%"
              ></v-text-field>
            </div>

            <div class="row mt-7">
              <span class="font-weight-bold">
                2. Participant is applying as an (check one of the following):
              </span>
            </div>

            <div class="row">
              <div class="col">
                <v-radio-group v-model="selectedType">
                  <v-radio label="Individual" value="individual" />
                  <v-radio
                    label="Entity (Corporation, Limited Partnership, Trust, Estate, etc.)"
                    value="entity"
                  />
                  <v-radio
                    label="Joint Operation (General Partnership, Joint Venture)"
                    value="joint-operation"
                  />
                </v-radio-group>
              </div>
            </div>

            <div class="row">
              <div class="col-12">
                <span class="default-input-title font-weight-bold">
                  a. Please Enter Legal name, Tax ID or SS# and if different
                  from the entity name, the name of the person to whom EA
                  payments are made.
                </span>
              </div>

              <div class="col-12 ml-3">
                <span class="default-input-title font-weight-bold">
                  Grower Name or Entity Name:
                </span>
                <v-tooltip top max-width="300">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon v-bind="attrs" v-on="on"> mdi-information </v-icon>
                  </template>
                  <span>
                    Fill in the box to the right with a grower or entity name
                    that will be paid.
                  </span>
                </v-tooltip>
                <div class="input-group">
                  <input
                    required
                    type="text"
                    class="form-control fieldInput"
                    v-model.trim="entityName"
                  />
                </div>
              </div>

              <div class="col-12 ml-3">
                <span class="default-input-title font-weight-bold">
                  Tax ID:
                </span>
                <v-tooltip top max-width="300">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon v-bind="attrs" v-on="on"> mdi-information </v-icon>
                  </template>
                  <span>
                    Fill in the box to the right with a tax id number.
                  </span>
                </v-tooltip>
                <div class="input-group">
                  <input
                    required
                    type="text"
                    class="form-control fieldInput"
                    v-model.trim="taxId"
                    placeholder="Optional"
                  />
                </div>
              </div>
            </div>

            <div class="row mb-3">
              <div class="col-12">
                <span class="default-input-title font-weight-bold">
                  b. If the entity is a joint operation, do you have the the
                  appropriate documents including proof to sign for the joint
                  operation?
                </span>
              </div>
              <div class="col ml-3">
                <v-radio-group v-model="hasProofForJO">
                  <v-radio label="Yes" value="yes" />
                  <v-radio label="No" value="no" />
                </v-radio-group>
              </div>
            </div>

            <div class="row mt-7">
              <span class="font-weight-bold">
                3. Is the Land offered under this enrollment in any other
                environmental asset (carbon offset, sustainability program,
                scope 3 emission claims, biodiversity claims, or water credits)
                program?
              </span>
            </div>

            <div class="row">
              <div class="col-12 ml-3">
                <v-radio-group v-model="hasEnrolledOnOtherProgram">
                  <v-radio label="Yes" value="yes" />
                  <v-radio label="No" value="no" />
                </v-radio-group>
              </div>

              <div class="col-12">
                <span class="font-weight-bold"> If yes, please describe: </span>
                <div class="input-group">
                  <input
                    :disabled="hasEnrolledOnOtherProgram !== 'yes'"
                    required
                    type="text"
                    class="form-control fieldInput"
                    v-model.trim="otherProgram"
                  />
                </div>
              </div>
            </div>

            <div class="row mt-7">
              <span>
                Submission of this Application does not guarantee acceptance by
                Arva. Arva reserves the right to accept or reject any
                Application in its sole discretion.<br /><br />
                The Participant understands that once a best-execution plan is
                designed and presented, additional agreements and other matters
                may be required before approval of participation in any Arva
                Environmental Asset Program, including but not limited to: i) a
                definitive enrollment agreement, ii) execution of a Grower
                Agreement, iii) Arva Software Services and restricted user
                license, iv) Farm data and farm operations ownership including
                land registration with appropriate authorities. Arva does not
                guarantee that Participant will be able to satisfy any or all of
                these requirements.<br /><br />
                This binding LOI ensures that it is the responsibility of the
                Participant to provide Arva and/or the Participants Channel
                Partner with accurate information needed to support the
                generation of validated Environmental Asset Certificates (EACs).
                Participant consents to the use of all information submitted for
                purposes of evaluating the land in connection with Arva's
                Environmental Asset Programs, and consents to the disclosure of
                any such information to third parties as may be reasonably
                required as outlined in Arva’s Data Privacy Policy in connection
                with such evaluations. Participant certifies that no other
                consents are required for Arva to use and disclose Participant's
                information as described here.<br /><br />
                By Signing this LOI, Participant certifies that all information
                and any accompanying materials provided to Arva or the Channel
                Partner is accurate and complete in all respects to the best of
                Participant's knowledge. Additional information on Arva's
                Environmental Asset Programs may be found online at
                arvaintelligence.com. Specific questions about EA programs
                should be directed to the Channel Partner.<br /><br />
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <ArvaModalSaveFooter
          confirmText="Send To Client"
          cancelText="Cancel"
          :disabled="disableAcceptBtn"
          @on-cancel="handleCloseModal"
          @on-confirm="sendLOI"
        />
      </div>
    </div>
  </ArvaModal>
</template>

<script>
import ArvaModal from "./ArvaModal"
import ArvaModalSaveFooter from "@/components/modals/ArvaModalSaveFooter"

export default {
  name: "LetterOfIntentModal",
  props: ["selectedClient", "org"],
  components: { ArvaModal, ArvaModalSaveFooter },
  data() {
    return {
      rules: {
        required: value => !!value || "Required.",
      },
      processingEnrollment: false,
      participantName: this.selectedClient
        ? this.selectedClient.client.name
        : "",
      participantAddress: {
        street: this.selectedClient ? this.selectedClient.street_address : "",
        city: this.selectedClient ? this.selectedClient.city : "",
        state: this.selectedClient ? this.selectedClient.state : "",
        zip: this.selectedClient ? this.selectedClient.zip_code : "",
      },
      participantEmail: this.selectedClient
        ? this.selectedClient.client.email
        : "",
      participantTelephone: this.selectedClient
        ? this.selectedClient.phone_number
        : "",
      cpName: this.org ? this.org.name : "",
      cpAddress: {
        street: "",
        city: "",
        state: "",
        zip: "",
      },
      cpEmail: this.org ? this.org.corporation.user.email : "",
      cpTelephone: "",
      selectedType: "individual",
      entityName: "",
      taxId: "",
      hasProofForJO: "no",
      hasEnrolledOnOtherProgram: "no",
      otherProgram: "",
    }
  },
  methods: {
    handleCloseModal() {
      this.$emit("close-modal")
    },
    sendLOI() {
      const payload = {
        client_profile_id: this.selectedClient.id,
        participant_name: this.participantName,
        participant_address: this.participantAddress,
        participant_email: this.participantEmail,
        participant_telephone: this.participantTelephone,
        cp_name: this.cpName,
        cp_address: this.cpAddress,
        cp_email: this.cpEmail,
        cp_telephone: this.cpTelephone,
        selected_type: this.selectedType,
        entity_name: this.entityName,
        tax_id: this.taxId,
        has_proof_for_join_operation: this.hasProofForJO,
        has_enrolled_on_other_program: this.hasEnrolledOnOtherProgram,
        other_program: this.otherProgram,
      }
      this.$emit("send-loi", payload)
    },
  },
}
</script>

<style scoped>
.info-text {
  margin: 20px 0px;
  font-size: 15px;
  text-align: center;
}

.span-text {
  padding: 20px;
  font-size: 15px;
  text-align: center;
}

.order-row {
  padding: 0px 140px;
  font-weight: 500;
}

.order-row > div {
  margin: 5px 0px;
}

.agreement {
  padding: 20px 35px;
  background: #f9f9f9;
  border: 1pt solid #f0f0f0;
  max-height: 550px;
  overflow-y: scroll;
}

.agreement .logo {
  background-image: url("/assets/images/logos/arva-logo.png");
  background-size: cover;
  height: 40px;
  width: 120px;
  margin-left: -5px;
  margin-bottom: 12px;
}

.confirm {
  margin-left: 3%;
}

.pdf-link {
  font-weight: 500;
}

.importantText {
  color: green;
}

h1,
h3,
h5 {
  font-weight: bold;
}

.headerText {
  font-weight: bold;
  font-size: 15px;
  display: inline-block;
  width: 105px;
}

.normalText {
  padding-left: 30px;
}

.customizeStepper {
  min-width: 180px;
}

.fieldInput {
  border: 1px solid #050607;
  border-radius: 2px;
  box-shadow: none;
  height: 30px;
  width: 300px;
  font-size: 14px;
  color: #0a0a0d;
  transition: all 0.2s ease-in;
  -webkit-transition: all 0.2s ease-in;
  -moz-transition: all 0.2s ease-in;
  -o-transition: all 0.2s ease-in;
  -ms-transition: all 0.2s ease-in;
}

.carbonReadyFieldModal {
  width: 1000px;
}

.error-message {
  font-size: 15px;
  font-weight: 500;
  color: red;
}

.input-section > .col {
  padding-right: 5px;
  padding-bottom: 5px;
}
</style>
